import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, VERSION } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateClaimPackageComponent } from '../create-claim-package/create-claim-package.component';
import { PdfViewComponent } from '../pdf-view/pdf-view.component';
import { HttpClientModule, HttpRequest, HttpResponse, HttpEventType } from '@angular/common/http';
import { apiService } from 'src/app/_services/app.service';
import _ from 'lodash';

@Component({
    selector: 'app-create-claim',
    templateUrl: './create-volume-package.component.html',
    styleUrls: ['./create-volume-package.component.css']
})
export class CreateVolumePackageComponent implements OnInit {
    claim_number: any;
    cancel: boolean = true;
    showLoader = false;
    firstErrorEnabled = false;
    username: any;
    //file upload
    // percentDone: number;
    // uploadSuccess: boolean;
    availableTemplates = [
        { name: 'mx_volume_cover_letter', selected: false },
        { name: 'mx_volume_approval', selected: false },
        { name: 'mx_volume_summary', selected: false },
        { name: 'mx_volume_purchase', selected: false },
        { name: 'mx_volume_deduction', selected: false }
    ];
    selectedTemplates = [];
    selectedFilenames: string[] = [];
    clCode: string; // Example clCode, update as needed
    auditTypeID: string;



    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private http: HttpClient, public dialogRef: MatDialogRef<CreateVolumePackageComponent>, private apiservice: apiService) {}
    version = VERSION;





ngOnInit() {
    this.claim_number = this.data.claim_number;
    console.log(' this.claim_number', this.claim_number);
    
    // Retrieve user info from localStorage
    const prompt = JSON.parse(localStorage.getItem('userinfo'));
    if (prompt && prompt.username) {
        this.username = prompt.username;
        console.log('Username:', this.username);
    } else {
        console.error('Username not found in localStorage');
    }
}

claimVolumeTemplateUpload() {
    if (this.claim_number != null) {
        this.showLoader = true;

        const filenames = this.selectedTemplates.map(template => template.name); 
        console.log('Selected Templates:', this.selectedTemplates);
        console.log('Filenames for Payload:', filenames);

        if (filenames.length === 0) {
            alert('No templates selected');
            this.showLoader = false;
            return;
        }

        
        this.apiservice.claimVolumeTemplateUploadData(
            this.claim_number,
            this.username.replace(/\s/g, ''),
            this.data.country,
            filenames
        ).subscribe(
            (response) => {
                console.log('Upload response:', response);
                this.getFileDetails(this.claim_number);
                this.showLoader = false;
            },
            (error) => {
                console.error('Upload Error:', error);
                this.showLoader = false;
                this.firstErrorEnabled = true;
                this.createNew([]);
            }
        );
    } else {
        alert('Please select a claim number');
    }
}


getFileDetails(id) {
    this.showLoader = true;
    this.apiservice.getFileDetails(id).subscribe(
        (response) => {
            console.log('File details response:', response);
            this.createNew(response);
            this.showLoader = false;
        },
        (error) => {
            console.error('Error fetching file details:', error);
            this.showLoader = false;
            if (!this.firstErrorEnabled) {
                alert('Data not present in the system');
            }
        }
    );
}

createNew(fileDetails) {
    this.showLoader = false;
    const dialogRef = this.dialog.open(CreateClaimPackageComponent, {
        hasBackdrop: true,
        data: {
            fileDetail: fileDetails,
            claim_data: this.data.claim_info,
            claim_number: this.data.claim_number,
            creationDate: this.data.claimCreationDate,
            country: this.data.country
        },
        width: '700px'
    });
    dialogRef.afterClosed().subscribe((result) => {});
}

viewPdf() {
    if (this.claim_number != null && this.claim_number !== undefined) {
        if (this.data.fileData && this.data.fileData.length > 0) {
            this.dialog.open(PdfViewComponent, {
                data: this.data.fileData
            });
        } else {
            alert('No File Available!');
        }
    } else {
        alert('Please select a claim number');
    }
}

selectTemplate(template: any, side: 'available' | 'selected') {
    template.selected = !template.selected;  // Toggle selection
    console.log(`${template.name} selected:`, template.selected); // Log selection
}

moveToSelected() {
    const selectedTemplates = this.availableTemplates.filter(template => template.selected);
    this.selectedTemplates.push(...selectedTemplates);
    this.availableTemplates = this.availableTemplates.filter(template => !template.selected);
    console.log('Moved to Selected:', this.selectedTemplates);
}

moveAllToSelected() {
    this.selectedTemplates = this.selectedTemplates.concat(this.availableTemplates);
    this.availableTemplates = [];
    console.log('All templates moved to Selected');
}

moveToAvailable() {
    const selected = this.selectedTemplates.filter(template => template.selected);
    this.availableTemplates.push(...selected);
    this.selectedTemplates = this.selectedTemplates.filter(template => !template.selected);
    console.log('Moved to Available:', this.availableTemplates);
}

moveAllToAvailable() {
    this.availableTemplates = this.availableTemplates.concat(this.selectedTemplates);
    this.selectedTemplates = [];
    console.log('All templates moved to Available');
}
}

